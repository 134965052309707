import React from 'react'
import { Carousel } from 'antd'

import * as styles from './product.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ProductGallery = ({ gallery }) => {
    if (!gallery || gallery.length === 0) return null

    return (
        <Carousel
            draggable={true}
            slidesToScroll={1}
            easing='cubic-bezier(0.65, 0.05, 0.36, 1)'
            className={styles.gallery}
        >
            {gallery.map(node => {
                const image = node && node.localFile ? getImage(node.localFile) : null

                return (
                    <GatsbyImage
                        key={node.id}
                        className={styles.post__image}
                        image={image}
                        alt={node.alt ? node.alt : 'Gallery image'}
                    />
                )
            })}
        </Carousel>
    )
}

export default ProductGallery
